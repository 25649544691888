@import 'main.scss';

.navbar-toggler{
    border: 2px solid $main-color;
    width: 50px;
    height: 47px;
    cursor: pointer;
    padding: 0;
    align-self: center;

    span{
        display: block;
        width: 80%;
        height: 3px;
        margin: 0 auto;
        margin-bottom: 8px;
        background-color: $main-color;
        background-image: none;
        outline:solid 1px transparent;
        transition:all .16s ease-in-out;
        transform: rotate(0) translate(0);

        &:first-child{
            margin-top:8px;
        }
    }

    &.active{
        span{
            transform: rotate(-45deg);
            transform-origin:center;


            &:first-child{
                transform: rotate(45deg) translate(4px, -6px);
                transform-origin:left top;
            }
            &:last-child{
                transform: rotate(45deg) translate(-4px,  5px);
                transform-origin:right bottom;
            }
        }
    }
}

@media (min-width: 1200px){
    .container {
        max-width: 1170px;
    }
}
//md
@media screen and (max-width:991px){
    #header{
        .nav-item{
            height: auto;
        }
    }

}
//sm
@media screen and (max-width:767px){
    aside{
        margin-top: 30px;
        iframe{
            height: 315px;
        }

        .block{
            text-align: center;
        }
    }

}
@media screen and (max-width:691px){

}
//xs
@media screen and (max-width:575px){
    .navbar-brand{
        max-width: 160px;
    }

    .navbar-toggler {
        margin-left: 10px;
        width: 35px;
        height: 32px;

        span {
            margin-bottom: 5px;
            height: 2px;
            width: 70%;

            &:first-child {
                margin-top: 5px;
            }
        }

        &.active span {
            transform: rotate(-45deg);
            transform-origin: center;

            &:first-child {
                transform: rotate(45deg) translate(3px, -3px);
                transform-origin: left top;
            }

            &:last-child {
                transform: rotate(45deg) translate(-2px, 3px);
                transform-origin: right bottom;
            }
        }
    }
}

@media screen and (max-width:480px){
}

@media screen and (max-width:360px){
    .navbar-brand{
        max-width: 130px;
    }
}