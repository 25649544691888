@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&subset=cyrillic,cyrillic-ext,latin-ext");

@import url("https://fonts.googleapis.com/css?family=Cuprum:400,700&subset=cyrillic,cyrillic-ext,latin-ext");

$black: #58585A;
$main-color: #008053;
$yellow: #fece1b;

* {
    font-family: 'Open Sans';
}

html {
    font-size: 100%;
}

a{
    color: $main-color;
    transition: color .16s ease-in-out;
    &:hover {
        color: #bababa;
    }
}


#header {
    background-color: #ffffff;
    position: static;
    position: sticky;
    z-index: 100;
    top: 0;
    will-change: transform;

    .nav-link {
        color: $main-color;
        font-weight: 700;
        transition: color .16s ease-in-out;
        padding: 7px 15px;
        font-size: 0.875rem;

        &.active {
            color: #747474;
        }

        &:hover {
            color: #bababa;
        }
    }

    .nav-item {
        position: relative;
        height: 70px;

        &.active {
            .nav-link {
                color: #747474;
            }

            &::after {
                content: '';
                display: block;
                height: 4px;
                width: 100%;
                background-color: $yellow;
                position: absolute;
                bottom: 0;
            }
        }

        .submenu {
            position: absolute;
            top: 100%;
            background: #ffffff;
            padding: 0 10px;
            box-shadow: 7px 8px 16px -7px rgba(0, 0, 0, 0.15), 0px 8px 16px -7px rgba(0, 0, 0, 0.15), -7px 2px 16px -7px rgba(0, 0, 0, 0.15);
            transform: scaleY(0);
            opacity: 0;
            visibility: hidden;
            transform-origin: top left;
            transition: opacity .16s ease-in-out .0s, transform .2s ease-in-out 0.05s, visibility 0s linear .16s;

            .nav-item {
                width: 100%;
                min-width: 150px;
                height: auto;

                .nav-link {
                    padding: 5px 10px;
                }
            }

            &.visible {
                visibility: visible;
                transform: scale(1);
                opacity: 1;
                transition: opacity .16s ease-in-out .1s, transform .2s ease-in-out 0s, visibility 0s linear 0s;
            }
        }

        &:hover {
            .submenu {
                visibility: visible;
                transform: scale(1);
                opacity: 1;
                transition: opacity .16s ease-in-out .1s, transform .2s ease-in-out 0s, visibility 0s linear 0s;
            }
        }
    }

    .left {
        padding: 5px 0;

        .action-link {
            margin-top: 20px;
            margin-left: 10px;

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    .navbar {
        padding: 0;
    }
}

.navbar-brand {
    margin-right: 20px;
}

.langmenu {
    a {
        color: #505050;
        font-weight: 700;
        font-size: 0.8125rem;
    }
}

#highlighted-pages {
    margin: 30px -15px;

    > [class^="col"] {
        margin-bottom: 15px;
    }

    a {
        color: #ffffff;
        text-shadow: 0 0 8px rgba(0, 0, 0, 1);
        text-decoration: none;
    }

    .page {
        padding: 30px 20px;
        height: 100%;
        background-position: center center;
        background-size: cover;
        transition: box-shadow .16s ease-in-out;

        &:hover{
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        }
    }
}

h3 {
    font-family: 'Cuprum';
    font-weight: 700;
    font-size: 1.5em;
}

.carousel-wrap {
    position: relative;
    padding: 10px 0;
    border-radius: 5px;
    overflow: hidden;
    will-change: transform;

    .c-control {
        display: block;
        width: 40px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: right 5px center, center center;
        background-size: 16px, 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        background-image: url("/images/html/arrow-right-green.svg"), linear-gradient(to right, rgba(255, 255, 255, 0.0001), rgba(255, 255, 255, 1) 20px);
        z-index: 10;

        &.carousel-next {
            right: 0;
            transform: translateY(-50%) rotate(0);
        }
    }

    .item-carousel {
        padding: 0 20px;

        .item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            padding: 0 20px;

            &:nth-child(n+2) {
                display: none;
            }

            img {
                width: auto;
            }
        }
    }
}

footer {
    background-color: #ededed;

    .navbar-nav {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

        .nav-item {
            flex: 0 0 auto;
            position: relative;

            .nav-link {
                color: $main-color;
                font-weight: 700;
                transition: color .16s ease-in-out;
                padding: 7px 15px;
                font-size: 0.875rem;
                position: relative;

                &.active {
                    color: #747474;
                }

                &:hover {
                    color: #bababa;
                }
            }

            &::after {
                content: '';
                height: 100%;
                border-right: solid 2px #686868;
                position: absolute;
                right: 0;
                top: 50%;
                height: 1em;
                transform: translateY(-50%);
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }

    .top {
        padding: 20px 0;
    }

    .bottom {
        border-top: 1px solid #d7d7d7;
        text-align: center;
        font-size: 0.8125rem;
        color: #686868;
        padding: 10px 0 20px 0;

        p {
            margin-bottom: 0;
        }
    }
}

.news-list {
    padding: 25px;
    background-color: #fafafa;

    .news-item{
        padding: 20px 0 0 0;
        .date{
            font-size: 0.875rem;
        }

        .text{
            border-bottom: 1px solid #d7d7d7;
            padding-bottom: 25px;
        }

        &:first-of-type{
            padding-top: 0;
        }

        &:last-of-type{
            .text{
                border-bottom: 0;
            }
        }
    }
}

.more{
    font-weight: 700;
    white-space: pre;
}

iframe {
    max-width: 100%;
    max-height: 100%;
}

aside {
    iframe {
        height: 202px;
    }

    .block {
        margin-top: 10px;
        margin-bottom: 35px;

        .more{
            margin-top: 5px;
            float: right;
        }

        &::after{
            content: '';
            display: block;
            clear: both;
        }
    }
}

img{
    max-width: 100%;
}

.carousel-indicators{
    li{
        cursor: pointer;
    }
}